import React, { useState } from "react"
import EducatorForm from "../components/forms/educator"
import IndustryForm from "../components/forms/industry"
import StudentForm from "../components/forms/student"
import Layout from "../components/layout"
import SEO from "../components/seo"

const JoinPage = () => {
  const [userType, setUserType] = useState<string>("Student")
  const [listOrder, setListOrder] = useState([
    "Educator",
    "Student",
    "Industrial",
  ])

  const changeUserType = (type: string) => {
    setUserType(type)
  }
  return (
    <Layout>
      <SEO title="Join Us" description="" lang="en" meta={[]} />
      <section className="register-page bg-darkened">
        <div className="container">
          <div className="row">
            <h3 className="text-center">Welcome!</h3>
            <p className="text-center">Please help us get acquainted.</p>
            <div className="user-type-select">
              {userType === "Educator" || userType === "Industrial" ? (
                <h5>I am an...</h5>
              ) : (
                <h5>I am a...</h5>
              )}
              <ul>
                {listOrder.map(item => (
                  <li
                    key={item}
                    onClick={() => {
                      if (userType !== item) {
                        changeUserType(item)
                      }
                    }}
                    className={userType === item ? "selected" : ""}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row">
            {userType === "Student" && <StudentForm purpose="Registration" />}
            {userType === "Educator" && <EducatorForm purpose="Registration" />}
            {userType === "Industrial" && (
              <IndustryForm purpose="Registration" />
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default JoinPage
